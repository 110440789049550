import React, { useState } from 'react';
import './table.css';
import { MdDelete } from 'react-icons/md';
import { IoMdCheckmark, IoMdClose } from 'react-icons/io';  // Importing the close icon for deny
import { query, where, getDocs, updateDoc, collection, deleteDoc } from 'firebase/firestore';
import { db } from '../config/firebase.config';
import * as XLSX from 'xlsx';

const TableJsxOrders = ({ ordershistory }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [statusFilter, setStatusFilter] = useState('All');
    const [filterDate, setFilterDate] = useState('');
    const [productNameFilter, setProductNameFilter] = useState('');
    const [customerNameFilter, setCustomerNameFilter] = useState('');
    const rowsPerPage = 10;

    const convertDateInput = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
    };

    const filteredOrders = ordershistory?.filter(order => {
        if (statusFilter !== 'All' && order?.status !== statusFilter) return false;
        if (filterDate) {
            const selectedDateFormatted = convertDateInput(filterDate);
            if (order.orderDate !== selectedDateFormatted) return false;
        }
        if (productNameFilter && !order.productName.toLowerCase().includes(productNameFilter.toLowerCase())) return false;
        if (customerNameFilter && !order.customerName.toLowerCase().includes(customerNameFilter.toLowerCase())) return false;
        return true;
    });

    const sortedOrders = filteredOrders?.sort((a, b) => {
        const dateA = new Date(`${a.orderDate.split('/').reverse().join('-')} ${a.orderTime}`);
        const dateB = new Date(`${b.orderDate.split('/').reverse().join('-')} ${b.orderTime}`);
        return dateA - dateB;
    });

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = sortedOrders?.slice(indexOfFirstRow, indexOfFirstRow + rowsPerPage);
    const totalPages = Math.ceil(sortedOrders?.length / rowsPerPage);

    const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
    const handlePreviousPage = () => setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    const handleNextPage = () => setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));

    const handleStatusFilterChange = (event) => {
        setStatusFilter(event.target.value);
        setCurrentPage(1);
    };

    const handleDateChange = (event) => {
        setFilterDate(event.target.value);
        setCurrentPage(1);
    };

    const handleProductNameChange = (event) => {
        setProductNameFilter(event.target.value);
        setCurrentPage(1);
    };

    const handleCustomerNameChange = (event) => {
        setCustomerNameFilter(event.target.value);
        setCurrentPage(1);
    };

    const handleCheckmarkClick = async (orderId, productId, orderQuantity) => {
        try {
            const productQuery = query(collection(db, 'products'), where('barcode', '==', productId));
            const productSnapshot = await getDocs(productQuery);

            if (!productSnapshot.empty) {
                const productDoc = productSnapshot.docs[0];
                const productData = productDoc.data();

                if (orderQuantity > productData.quantity) {
                    alert("Insufficient quantity");
                    return;
                }

                const newQuantity = (productData?.quantity || 0) - orderQuantity;
                await updateDoc(productDoc.ref, { quantity: newQuantity });

                const orderQuery = query(collection(db, 'orders'), where('orderIdUnique', '==', orderId));
                const orderSnapshot = await getDocs(orderQuery);

                if (!orderSnapshot.empty) {
                    const orderDoc = orderSnapshot.docs[0];
                    await updateDoc(orderDoc.ref, { status: 'Accepted' });

                    alert('Order accepted and product quantity updated.');
                } else {
                    alert('No order found with the provided ID.');
                }
            } else {
                alert('No product found with the provided barcode.');
            }
        } catch (error) {
            console.error('Error updating order or product:', error);
            alert('Failed to update order or product.');
        }
    };

    const handleDenyClick = async (orderId) => {
        try {
            const orderQuery = query(collection(db, 'orders'), where('orderIdUnique', '==', orderId));
            const orderSnapshot = await getDocs(orderQuery);

            if (!orderSnapshot.empty) {
                const orderDoc = orderSnapshot.docs[0];
                await updateDoc(orderDoc.ref, { status: 'Denied' });

                alert('Order denied.');
            } else {
                alert('No order found with the provided ID.');
            }
        } catch (error) {
            console.error('Error denying the order:', error);
            alert('Failed to deny the order.');
        }
    };

    const handleDeleteClick = async (orderId, customerEmail) => {
        try {
            // Delete from user's order history collection
            const userOrderQuery = query(collection(db, `users/${customerEmail}/orders`), where('orderIdUnique', '==', orderId));
            const userOrderSnapshot = await getDocs(userOrderQuery);

            if (!userOrderSnapshot.empty) {
                const userOrderDoc = userOrderSnapshot.docs[0];
                await deleteDoc(userOrderDoc.ref);
                console.log('Order deleted from user history');
            } else {
                console.log('No order found in user history with the provided ID.');
            }

            // Delete from general orders collection
            const orderQuery = query(collection(db, 'orders'), where('orderIdUnique', '==', orderId));
            const orderSnapshot = await getDocs(orderQuery);

            if (!orderSnapshot.empty) {
                const orderDoc = orderSnapshot.docs[0];
                await deleteDoc(orderDoc.ref);
                alert('Order deleted from both user and orders history.');
            } else {
                alert('No order found with the provided ID.');
            }
        } catch (error) {
            console.error('Error deleting the order:', error);
            alert('Failed to delete the order.');
        }
    };

    // Function to export data to XLSX
    const handleExportXLSX = () => {
        const exportData = sortedOrders.map(order => ({
            ID: order.id,
            'Customer Name': order.customerName,
            'Product Name': order.productName,
            Email: order.email,
            Quantity: order.orderQuantity,
            Price: order.orderPrice,
            SKU: order.sku,
            Date: order.orderDate,
            Status: order.status,
        }));

        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Orders');

        XLSX.writeFile(workbook, 'OrdersHistory.xlsx');
    };

    return (
        <div>
            {/* Filters */}
            <div className="filters">
                <div className="filter-flexx">
                    <div className="product-filter">
                        <label htmlFor="productNameFilter">Filter by Product Name: </label>
                        <input
                            type="text"
                            id="productNameFilter"
                            value={productNameFilter}
                            onChange={handleProductNameChange}
                            placeholder='Enter Product Name'
                        />
                    </div>

                    <div className="customer-filter">
                        <label htmlFor="customerNameFilter">Filter by Customer Name: </label>
                        <input
                            type="text"
                            id="customerNameFilter"
                            value={customerNameFilter}
                            placeholder='Enter Customer Name'
                            onChange={handleCustomerNameChange}
                        />
                    </div>
                </div>
                <div className="filter-flexx">
                    <div className="status-filter">
                        <label htmlFor="statusFilter">Filter by Status: </label>
                        <select id="statusFilter" value={statusFilter} onChange={handleStatusFilterChange}>
                            <option value="All">All</option>
                            <option value="In Review">In Review</option>
                            <option value="Accepted">Accepted</option>
                            <option value="Denied">Denied</option> {/* Added Denied option */}
                        </select>
                    </div>

                    <div className="date-filter">
                        <label htmlFor="filterDate">Filter by Date: </label>
                        <input
                            type="date"
                            id="filterDate"
                            value={filterDate}
                            onChange={handleDateChange}
                        />
                    </div>
                </div>
            </div>

            {/* Export Button */}
            <div className="export-div">
                <button className="exportBtn" onClick={handleExportXLSX}>Export</button>
            </div>

            {/* Orders Table */}
            <section className="tablePage">
                <table>
                    <thead>
                        <tr>
                            {/* <th>ID</th> */}
                            <th>Customer Name</th>
                            <th>Product Name</th>
                            <th>Email</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>SKU</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentRows?.map((order, index) => (
                            <tr key={index}>
                                {/* <td>{order?.id}</td> */}
                                <td>{order?.customerName}</td>
                                <td>{order?.productName ? (order?.productName.length > 10 ? `${order?.productName.slice(0, 10)}...` : order?.productName) : '-'}</td>
                                <td>{order?.email ? (order?.email.length > 10 ? `${order?.email.slice(0, 10)}...` : order?.email) : '-'}</td>
                                <td>{order?.orderQuantity || '-'}</td>
                                <td>{order?.orderPrice || '-'}</td>
                                <td>{order?.sku ? (order?.sku.length > 5 ? `${order?.sku.slice(0, 5)}...` : order?.sku) : '-'}</td>
                                <td>{order?.orderDate || '-'}</td>
                                <td><p className={`status ${order?.status.toLowerCase()}`}>{order?.status}</p></td>
                                <td>
                                    <div className="divContentDeleteorUpdate">
                                        {order?.status === 'In Review' && (
                                            <>
                                                <button
                                                    className="editMd"
                                                    style={{ background: 'green' }}
                                                    onClick={() => handleCheckmarkClick(order.orderIdUnique, order.barcode, order.orderQuantity)}
                                                >
                                                    <IoMdCheckmark />
                                                </button>
                                                <button
                                                    className="editMd"
                                                    style={{ background: 'red' }}
                                                    onClick={() => handleDenyClick(order.orderIdUnique)}
                                                >
                                                    <IoMdClose />
                                                </button>
                                            </>
                                        )}

                                        {/* Added Delete button */}
                                        <button
                                            className="deleteMd"
                                            onClick={() => handleDeleteClick(order.orderIdUnique, order.email)}
                                        >
                                            <MdDelete />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>

            {/* Pagination */}
            <div className="pagination">
                <button onClick={handlePreviousPage} disabled={currentPage === 1}>Previous</button>
                {pageNumbers.map(pageNumber => (
                    <button
                        key={pageNumber}
                        onClick={() => handlePageChange(pageNumber)}
                        className={currentPage === pageNumber ? 'active' : ''}
                    >
                        {pageNumber}
                    </button>
                ))}
                <button onClick={handleNextPage} disabled={currentPage === totalPages}>Next</button>
            </div>
        </div>
    );
};

export default TableJsxOrders;
