import React, { useState } from 'react';
import './table.css';
import * as XLSX from 'xlsx';

const TableJSXUsers = ({ user }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;

    // Calculate the indices for the current page slice
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = user?.slice(indexOfFirstRow, indexOfLastRow);

    // Calculate the total number of pages
    const totalPages = Math.ceil(user?.length / rowsPerPage);

    // Calculate the range of pages to be shown
    const maxPageNumbersToShow = 3;
    const halfMaxPageNumbersToShow = Math.floor(maxPageNumbersToShow / 2);

    let startPage = Math.max(currentPage - halfMaxPageNumbersToShow, 1);
    let endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);

    if (endPage - startPage < maxPageNumbersToShow - 1) {
        startPage = Math.max(endPage - maxPageNumbersToShow + 1, 1);
    }

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    // Function to handle page change
    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    // Function to handle previous page click
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Function to handle next page click
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Function to export data to XLSX
    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(user);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
        XLSX.writeFile(workbook, 'UsersList.xlsx');
    };

    return (
        <div>
            <div className="export-div">
                <button onClick={exportToExcel}>Export to Excel</button>
            </div>


            <section className="table__body">
                <table>
                    <thead>
                        <tr>
                            <th> ID</th>
                            <th> Customer Name </th>
                            <th> Email </th>
                            <th> Phone Number </th>
                            <th> UID </th>
                            <th> GSTIN </th>
                            <th> Company </th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentRows?.map((order) => (
                            <tr key={order.id}>
                                <td>{order.id ? (order.id.length > 10 ? `${order.id.slice(0, 10)}...` : order.id) : 'N/A'}</td>
                                <td>
                                    {/* <img src={order.photoUrl} alt={order.photoUrl} /> */}
                                    {order?.displayName || 'N/A'}
                                </td>
                                <td>{order?.email || 'N/A'}</td>
                                <td>{order?.phoneNumber || 'N/A'}</td>
                                <td>{order?.uid ? (order.uid.length > 10 ? `${order.uid.slice(0, 10)}...` : order.uid) : 'N/A'}</td>
                                <td>{order?.gstin ? (order.gstin.length > 10 ? `${order.gstin.slice(0, 10)}...` : order.gstin) : 'N/A'}</td>
                                <td>{order?.company ? (order.company.length > 10 ? `${order.company.slice(0, 10)}...` : order.company) : 'N/A'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>
            <div className="pagination paginationOrders">
                <button onClick={handlePreviousPage} id='BtnPagination' disabled={currentPage === 1}>{"<"}</button>
                {pageNumbers.map((number) => (
                    <button
                        key={number}
                        onClick={() => handlePageChange(number)}
                        className={`buttonPAginationCircle ${currentPage === number ? 'active' : ''}`}
                    >
                        {number}
                    </button>
                ))}
                <button onClick={handleNextPage} id='BtnPagination' disabled={currentPage === totalPages}>{">"}</button>
            </div>
        </div>
    );
};

export default TableJSXUsers;
