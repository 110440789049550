// Loading.jsx
import React from 'react';
import './loading.css';
import logo from '../assets/LOGO CURSIVE rectangular-02.png'

const Loading = ({ text }) => {
    return (
        <div className="loading-overlay">
            <img src={logo} alt="" />
            <div className="loading-text">{text}</div>
        </div>
    );
}

export default Loading;
