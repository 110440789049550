import React, { useState } from 'react';
import './table.css';
import { MdDelete, MdEdit } from 'react-icons/md';
import { deleteDoc, doc, updateDoc, getDocs, collection, query, where } from 'firebase/firestore';
import { db } from '../config/firebase.config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';

const TableJsxProducts = ({ products }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const rowsPerPage = 10;

    // Filter products based on search query
    const filteredProducts = products?.filter(product =>
        product.productName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Calculate the indices for the current page slice
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = filteredProducts?.slice(indexOfFirstRow, indexOfLastRow);

    // Calculate the total number of pages
    const totalPages = Math.ceil(filteredProducts?.length / rowsPerPage);

    // Calculate the range of pages to be shown
    const maxPageNumbersToShow = 3;
    const halfMaxPageNumbersToShow = Math.floor(maxPageNumbersToShow / 2);

    let startPage = Math.max(currentPage - halfMaxPageNumbersToShow, 1);
    let endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);

    if (endPage - startPage < maxPageNumbersToShow - 1) {
        startPage = Math.max(endPage - maxPageNumbersToShow + 1, 1);
    }

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    // Function to handle page change
    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    // Function to handle previous page click
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Function to handle next page click
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleDelete = async (id, category, subCategory, sku) => {
        try {
            await deleteDoc(doc(db, 'products', id));

            const q = query(collection(db, 'categories'), where('categoryName', '==', category));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const categoryDocRef = querySnapshot.docs[0].ref;
                const categoryData = querySnapshot.docs[0].data();

                const updatedMainCategoryProducts = categoryData.products.filter((product) => product.sku !== sku);

                const updatedSubCategories = categoryData.subCategories.map((subCat) => {
                    if (subCat.subCategoryName === subCategory) {
                        return {
                            ...subCat,
                            products: subCat.products.filter((product) => product.sku !== sku),
                        };
                    }
                    return subCat;
                });

                await updateDoc(categoryDocRef, {
                    products: updatedMainCategoryProducts,
                    subCategories: updatedSubCategories
                });
            }

            toast.success('Product Deleted from both products and category/subcategory!');
        } catch (error) {
            console.error('Error deleting product:', error);
            toast.error(`Can't Execute Function: ${error.message}`);
        }
    };

    const handleUpdateField = async (id, field, text) => {
        const newValue = prompt(`Enter new ${field}:`);

        if (newValue === null || newValue.trim() === '') {
            toast.error(`Invalid ${field}. Please enter a valid value.`);
            return;
        }

        const numericValue = parseFloat(newValue);
        if (isNaN(numericValue)) {
            toast.error(`Invalid ${field}. Please enter a valid number.`);
            return;
        }

        try {
            await updateDoc(doc(db, 'products', id), { [field]: numericValue });
            toast.success(`${text} updated successfully!`);
        } catch (error) {
            console.error(`Error updating ${field}:`, error);
            toast.error(`Failed to update ${field}. Please try again.`);
        }
    };

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(products);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Products');
        XLSX.writeFile(workbook, 'products.xlsx');
    };

    const importFromExcel = async (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet);

            try {
                const q = query(collection(db, 'products'));
                const querySnapshot = await getDocs(q);
                const existingProducts = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

                let processedCount = 0;

                json.forEach(async (item, index) => {
                    const existingProduct = existingProducts.find((product) => product.sku === item.sku);
                    if (existingProduct) {
                        const updatedFields = {};
                        if (existingProduct.quantity !== item.quantity) updatedFields.quantity = item.quantity;
                        if (existingProduct.boxQty !== item.boxQty) updatedFields.boxQty = item.boxQty;
                        if (existingProduct.packQuantity !== item.packQuantity) updatedFields.packQuantity = item.packQuantity;
                        if (existingProduct.price !== item.price) updatedFields.price = item.price;
                        if (existingProduct.minBuying !== item.minBuying) updatedFields.minBuying = item.minBuying;
                        if (existingProduct.productName !== item.productName) updatedFields.productName = item.productName;

                        if (Object.keys(updatedFields).length > 0) {
                            await updateDoc(doc(db, 'products', existingProduct.id), updatedFields);
                            toast.success(`Product with SKU: ${item.sku} updated successfully!`);
                        }
                    }

                    processedCount++;
                    setUploadProgress(((processedCount / json.length) * 100).toFixed(2));
                });
            } catch (error) {
                console.error('Error importing products:', error);
                toast.error(`Failed to import products: ${error.message}`);
            }
        };

        reader.readAsArrayBuffer(file);
    };

    return (
        <div>
            <div className="export-div">
                <button onClick={exportToExcel}>Export to Excel</button>
                <label>
                    <button
                        type="button"
                        onClick={() => document.getElementById('fileInput').click()}
                        style={{
                            background: `linear-gradient(to right, green ${uploadProgress}%, red ${uploadProgress}%)`,
                            transition: 'background 0.1s ease',
                        }}
                    >
                        Import to Excel
                    </button>
                    <input
                        type="file"
                        id="fileInput"
                        onChange={importFromExcel}
                        style={{ display: 'none' }}
                        accept=".xlsx, .xls"
                    />
                </label>
            </div>

            {/* Search Bar */}
            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Search by product name..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>

            <section className="table__body">
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Product Name</th>
                            <th>Quantity</th>
                            <th>Min Buying</th>
                            <th>Pack Content</th>
                            <th>Category</th>
                            <th>Sub Category</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentRows?.map((order) => (
                            <tr key={order?.id}>
                                <td>{order?.id ? (order?.id.length > 10 ? `${order?.id.slice(0, 10)}...` : order?.id) : '-'}</td>
                                <td>{order?.productName}</td>
                                <td>
                                    <div className="divContentDeleteorUpdate">
                                        {order?.quantity || '0'}
                                        <button className="editMd" style={{ color: '#47b6de', border: '1px solid #47b6de', background: '#fff' }} onClick={() => handleUpdateField(order?.id, 'quantity', 'Quantity')}><MdEdit /></button>
                                    </div>
                                </td>
                                <td>
                                    <div className="divContentDeleteorUpdate">
                                        {order?.minBuying || '0'}
                                        <button className="editMd" style={{ color: '#47b6de', border: '1px solid #47b6de', background: '#fff' }} onClick={() => handleUpdateField(order?.id, 'minBuying', 'Minimum Quantity')}><MdEdit /></button>
                                    </div>
                                </td>
                                <td>
                                    <div className="divContentDeleteorUpdate">
                                        {order?.packQuantity || '0'}
                                        <button className="editMd" style={{ color: '#47b6de', border: '1px solid #47b6de', background: '#fff' }} onClick={() => handleUpdateField(order?.id, 'packQuantity', 'Pack Quantity')}><MdEdit /></button>
                                    </div>
                                </td>
                                <td>{order?.category || '-'}</td>
                                <td>{order?.subCategory || '-'}</td>
                                <td>
                                    <div className="divContentDeleteorUpdate">
                                        <button className="editMd" onClick={() => window.location.href = `/product-list/${order?.id}/edit`}><MdEdit /></button>
                                        <button className="deleteMd" onClick={() => handleDelete(order?.id, order?.category, order?.subCategory, order?.sku)}><MdDelete /></button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>
            <div className="pagination paginationOrders">
                <button onClick={handlePreviousPage} disabled={currentPage === 1}>Previous</button>
                {pageNumbers.map((pageNumber) => (
                    <button key={pageNumber} className={currentPage === pageNumber ? 'active' : ''} onClick={() => handlePageChange(pageNumber)}>
                        {pageNumber}
                    </button>
                ))}
                <button onClick={handleNextPage} disabled={currentPage === totalPages}>Next</button>
            </div>
        </div>
    );
};

export default TableJsxProducts;
