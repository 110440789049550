import React, { useState, useEffect } from 'react';
import AdminNavbar from '../component/navbar/adminNavbar';
import TableJsxOrders from '../component/tableOrder';
import { db } from '../config/firebase.config';
import { collection, onSnapshot } from 'firebase/firestore';

const OrderHistory = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const ordersCollectionRef = collection(db, 'orders'); // Adjust the collection name if needed

        // Set up a real-time listener
        const unsubscribe = onSnapshot(ordersCollectionRef, (snapshot) => {
            try {
                const orderList = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                console.log(orderList);

                setOrders(orderList);
                setLoading(false); // Set loading to false once data is fetched
            } catch (error) {
                setError('Failed to fetch orders');
                console.error('Error fetching orders:', error);
            }
        }, (error) => {
            setError('Failed to fetch orders');
            console.error('Error fetching orders:', error);
        });

        // Cleanup listener on component unmount
        return () => unsubscribe();

    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div>
            <AdminNavbar displayNameTopbar="Order History" activeItemName="Order History">
                <TableJsxOrders ordershistory={orders} />
            </AdminNavbar>
        </div>
    );
};

export default OrderHistory;
