import './App.css';
import { Route, Routes } from 'react-router-dom';
import Protected from './protected';
import Login from './authentication/login';
import SignUp from './authentication/signup';
import SignOut from './authentication/signout';
import AddProduct from './route/addProduct';
import AddCategory from './route/addCategory';
import ProductList from './route/productList';
import OrderHistory from './route/orderHistory';
import CustomerJsx from './route/customer';
import EditProduct from './route/editProduct';
import AllCategory from './route/allCategory';
import WelcomeBoard from './route/welcome';

function App() {
  return (
    <Routes >
      <Route path='/' element={<Protected />}>
        <Route path='/' element={<WelcomeBoard></WelcomeBoard>} />
        <Route path='/order-history' element={<OrderHistory />} />
        <Route path='/customers' element={<CustomerJsx />} />
        <Route path='/add-product' element={<AddProduct />} />
        <Route path='/add-category' element={<AddCategory />} />
        <Route path='/all-category' element={<AllCategory />} />
        <Route path='/product-list' element={<ProductList />} />
        <Route path='/product-list/:productId/edit' element={<EditProduct />} />

      </Route>

      <Route path='/login' element={<Login />}></Route>
      <Route path='/signup' element={<SignUp />}></Route>
      <Route path='/signout' element={<SignOut />}></Route>
      <Route path='*' element={<h1>Page Not Found - Designing</h1>}></Route>
    </Routes>
  );
}

export default App;
