import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../config/firebase.config';
import { signOut } from 'firebase/auth';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SignOut = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const performSignOut = async () => {
            try {
                await signOut(auth);
                toast.success('You have been signed out.');
                navigate('/login'); // Redirect to login page or another page
            } catch (error) {
                console.error('Error signing out:', error);
                toast.error('Error signing out. Please try again.');
            }
        };

        performSignOut();
    }, [navigate]);

    return (
        <div>
            <ToastContainer />
        </div>
    );
};

export default SignOut;
