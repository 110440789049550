import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './navbar.css';
import logo from './../../assets/LOGO CURSIVE rectangular-02.png';
import { BiCaretDown, BiCaretUp, BiSolidCategory } from 'react-icons/bi';
import { AiFillProduct } from 'react-icons/ai';
import { MdAddHome, MdHistory } from 'react-icons/md';
import { FaUserAstronaut } from 'react-icons/fa';
import { CiBoxList } from 'react-icons/ci';
import { GrDashboard } from 'react-icons/gr';

const AdminNavbar = ({ children, displayNameTopbar, activeItemName }) => {
    const [openMenuIndex, setOpenMenuIndex] = useState(null);

    const handleMenuClick = (index) => {
        setOpenMenuIndex(openMenuIndex === index ? null : index);
    };

    const adminMenu = [
        {
            name: 'Dashboard',
            path: '/',
            icon: <GrDashboard />
        },
        {
            name: 'Order History',
            path: '/order-history',
            icon: <MdHistory />
        },
        // {
        //     name: 'Product Management',
        //     path: '/products',
        //     icon: <AiFillProduct />,
        //     // subItem: [
        //     //     {
        //     //         name: 'Add Product',
        //     //         path: '/add-product'
        //     //     },
        //     //     {
        //     //         name: 'Product List',
        //     //         path: '/product-list'
        //     //     }
        //     // ]
        // },
        {
            name: 'Customers',
            path: '/customers',
            icon: <FaUserAstronaut />
        }, {
            name: 'Add Category',
            path: '/add-category',
            icon: <BiSolidCategory />
        }, {
            name: 'All Category',
            path: '/all-category',
            icon: <BiSolidCategory />
        }, {
            name: 'Add Product',
            path: '/add-product',
            icon: <MdAddHome />
        },
        {
            name: 'Product List',
            path: '/product-list',
            icon: <CiBoxList />
        }
    ];

    return (
        <div className="navbar-admin--container">
            <div className="navbar-admin--logo--menu">
                <img src={logo} alt="Logo" style={{ height: '60px' }} />
                <ul className="admin-menu">
                    {adminMenu.map((item, index) => (
                        <li key={index} className={activeItemName === item.name ? 'active' : ''}>
                            <div onClick={() => handleMenuClick(index)}>
                                <div className="icon">{item.icon}</div>
                                <Link to={item.path}>{item.name}</Link>
                                {item.subItem && (
                                    <span className="dropdown-arrow">
                                        {openMenuIndex === index ? <BiCaretUp /> : <BiCaretDown />}
                                    </span>
                                )}
                            </div>
                            {item.subItem && openMenuIndex === index && (
                                <ul>
                                    {item.subItem.map((subItem, subIndex) => (
                                        <li key={subIndex}>
                                            - <Link to={subItem.path}>{subItem.name}</Link>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="navbar-admin--content">
                <div className="admin--content--row">
                    <div className="navbar--admin--tile">
                        {displayNameTopbar}
                    </div>
                    <div className="navbar--links--profile">
                        <div className="links--admin-navbar"></div>
                        <div className="profile-admin-navbar">
                            <div className="profileadmin-image">
                                <img src="https://techzaa.getappui.com/rasket/admin/assets/images/users/avatar-1.jpg" alt="Profile" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fullwidthcontainer" style={{ width: '100%' }}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default AdminNavbar;
