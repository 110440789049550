import React, { useState, useEffect, useRef } from 'react';
import AdminNavbar from '../component/navbar/adminNavbar';
import TableJsxProducts from '../component/table';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../config/firebase.config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ProductList = () => {
    const [products, setProducts] = useState([]);
    const firstLoad = useRef(true); // Initialize a ref to track the first load

    useEffect(() => {
        const productsCollection = collection(db, 'products');

        const unsubscribe = onSnapshot(
            productsCollection,
            (snapshot) => {
                const productsList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setProducts(productsList);

                // Only show the success toast on the first load
                if (firstLoad.current) {
                    toast.success('Products Fetched !!');
                    firstLoad.current = false; // Set the ref to false after the first load
                }
            },
            (error) => {
                console.error('Error fetching products:', error);
                toast.error('Product Fetching Failed !! Reload Recommended');
            }
        );

        // Cleanup the listener on component unmount
        return () => unsubscribe();
    }, []);

    return (
        <div>
            <AdminNavbar displayNameTopbar="All Products" activeItemName="Product List">
                <TableJsxProducts products={products} />
            </AdminNavbar>
            <ToastContainer />
        </div>
    );
};

export default ProductList;
