import React, { useEffect, useState } from 'react';
import AdminNavbar from '../component/navbar/adminNavbar';
import { useParams } from 'react-router-dom'
import './route.css';
import { MdOutlineCloudUpload } from 'react-icons/md';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { db, storage } from '../config/firebase.config';
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditProduct = () => {
    const { productId } = useParams()
    const [productName, setProductName] = useState('');
    const [productDescription, setProductDescription] = useState('');

    // Product Inventory
    const [quantity, setQuantity] = useState('');
    const [packQuantity, setPackQuantity] = useState('');
    const [boxQty, setBoxQty] = useState('');
    const [sku, setSku] = useState('');
    const [barcode, setBarcode] = useState('');
    const [productStatus, setProductStatus] = useState('')
    const [minBuying, setMinBuyin] = useState('')
    const [price, setPrice] = useState('')

    // Product Media
    const [featuredImage, setFeaturedImage] = useState(null);
    const [featuredImageUrl, setFeaturedImageUrl] = useState(null);
    const [galleryImages100, setGalleryImages100] = useState([]);
    const [galleryImages950, setGalleryImages950] = useState([]);
    const [galleryImages950Url, setGalleryImages950Url] = useState([]);

    // Product Category
    const [category, setCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);

    // Tags
    const [tags, setTags] = useState([]);
    const [tagInput, setTagInput] = useState('');

    //Meta Info
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaTags, setMetaTags] = useState('')

    useEffect(() => {
        const fetchProductData = async () => {
            try {
                const docRef = doc(db, "products", productId);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const prdData = docSnap.data()
                    console.log("Document data:", docSnap.data());
                    setQuantity(prdData.quantity)
                    setBarcode(prdData.barcode)
                    setPackQuantity(prdData.packQuantity)
                    setBoxQty(prdData.boxQty)
                    setSku(prdData.sku)
                    setProductStatus(prdData.productStatus)
                    setMinBuyin(prdData.minBuying)
                    setCategory(prdData.category)
                    setTags(prdData.productTags)
                    setSubCategory(prdData.subCategory)
                    setFeaturedImageUrl(prdData.featuredImageUrl)
                    setGalleryImages950Url(prdData.galleryImages950Url)
                    setProductDescription(prdData.productDescription)
                    setProductName(prdData.productName)
                    setPrice(prdData.price)
                } else {
                    // docSnap.data() will be undefined in this case
                    console.log("No such document!");
                }
            } catch {

            }
        }
        // Fetch categories
        const fetchCategories = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'categories'));
                const categoriesData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                setCategories(categoriesData);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };



        fetchCategories();
        fetchProductData()
    }, []);




    const handleCategoryChange = (e) => {
        const selectedCategoryId = e.target.value;
        setCategory(selectedCategoryId);

        // Fetch subcategories based on the selected category
        const selectedCategory = categories.find(cat => cat.categoryName === selectedCategoryId);
        if (selectedCategory) {
            setSubCategories(selectedCategory.subCategories || []);
        } else {
            setSubCategories([]);
        }
    };

    const handleSubCategoryChange = (e) => {
        setSubCategory(e.target.value);
    };
    const handleImageChange = (e, setImageState, multiple = false) => {
        const files = Array.from(e.target.files);
        if (multiple) {
            setImageState(prevState => [...prevState, ...files]);
        } else {
            setImageState(files[0]);
        }
    };

    const handleAddTag = () => {
        if (tagInput.trim()) {
            setTags([...tags, tagInput.trim()]);
            setTagInput("");
        }
    };

    const handleRemoveTag = (index) => {
        setTags(tags.filter((_, i) => i !== index));
    };

    const handleImageClick = (indexToRemove) => {
        const updatedImages = galleryImages950Url.filter((_, index) => index !== indexToRemove);
        setGalleryImages950Url(updatedImages); // Replace the array with the new one
    };



    const handleSubmit = async () => {
        let downloadUrlHere;
        const galleryImages950URLs = [];

        if (!minBuying) {
            toast.error('Enter Minimum Buying');
            return;
        }

        if (!price) {
            toast.error('Please Enter Price');
            return;
        }
        if (!quantity) {
            toast.error('Please Enter Quantity');
            return;
        }

        if (minBuying < packQuantity) {
            //also check if minBuyin / packQuantity returns a whole number in positive then only allow or else show error
            toast.error('Invalid Min Buying')
        }
        if (minBuying % packQuantity !== 0) {
            toast.error('Invalid Min Buying: Min buying quantity must be a multiple of the pack quantity.');
            return;
        }
        if (quantity % packQuantity !== 0) {
            toast.error('Invalid Quantity:  quantity must be a multiple of the pack quantity.');
            return;
        }

        const uploadFeaturedImage = async () => {
            if (!featuredImage) {
                console.error('No featured image provided');
                return;
            }

            try {
                const imageRef = ref(storage, `products/featured/${featuredImage.name}`);
                await uploadBytes(imageRef, featuredImage);
                const url = await getDownloadURL(imageRef);
                setFeaturedImageUrl(url);
                downloadUrlHere = url;

                console.log('Featured Image URL:', url);
                toast.success('Featured Image uploaded successfully!');
                return url;

            } catch (error) {
                console.error('Error uploading featured image:', error);
                toast.error('Error uploading featured image');
            }
            return downloadUrlHere;
        };

        const uploadGalleryImages100 = async () => {
            const galleryImages100URLs = [];

            if (!galleryImages100 || galleryImages100.length === 0) {
                console.error('No images to upload');
                return;
            }

            try {
                await Promise.all(
                    galleryImages100.map(async (image) => {
                        if (image && image.name) {
                            const imageRef = ref(storage, `products/gallery100/${image.name}`);
                            await uploadBytes(imageRef, image);
                            const url = await getDownloadURL(imageRef);
                            galleryImages100URLs.push(url);
                        } else {
                            console.error('Invalid image object:', image);
                        }
                    })
                );

                console.log('Gallery Image URLs:', galleryImages100URLs);
                toast.success('Gallery Images (100px) uploaded successfully!');
            } catch (error) {
                console.error('Error uploading gallery images:', error);
                toast.error('Error uploading gallery images (100px)');
            }
            return galleryImages100URLs;
        };

        const uploadGalleryImages950 = async () => {
            if (!galleryImages950 || galleryImages950.length === 0) {
                console.error('No images to upload');
                return;
            }

            try {
                await Promise.all(
                    galleryImages950.map(async (image) => {
                        if (image && image.name) {
                            const imageRef = ref(storage, `products/gallery950/${image.name}`);
                            await uploadBytes(imageRef, image);
                            const url = await getDownloadURL(imageRef);
                            galleryImages950URLs.push(url);
                        } else {
                            console.error('Invalid image object:', image);
                        }
                    })
                );

                console.log('Gallery Image URLs:', galleryImages950URLs);
                toast.success('Gallery Images (950px) uploaded successfully!');

                // Update the state with the URLs
                setGalleryImages950Url(galleryImages950URLs);
            } catch (error) {
                console.error('Error uploading gallery images:', error);
                toast.error('Error uploading gallery images (950px)');
            }
        };

        await uploadFeaturedImage();
        await uploadGalleryImages100();
        await uploadGalleryImages950();

        const productSchema = {
            productName,
            productDescription,
            quantity,
            packQuantity,
            price: price,
            featuredImageUrl: downloadUrlHere ? downloadUrlHere : featuredImageUrl,
            galleryImages950Url: [...galleryImages950URLs, ...galleryImages950Url],
            category,
            subCategory: subCategory ? subCategory : null,
            boxQty: boxQty,
            sku,
            productTags: tags ? tags : null,
            minBuying: minBuying ? minBuying : null,
            productStatus,
            barcode
        };

        try {
            // Reference to the product document
            const productDocRef = doc(db, "products", productId);

            // Update the product document
            await updateDoc(productDocRef, productSchema);
            console.log("Document updated with ID: ", productId);
            toast.success('Product updated successfully!');

            // Query to find the main category document
            const categoryQuery = query(collection(db, "categories"), where("categoryName", "==", category));
            const querySnapshot = await getDocs(categoryQuery);

            if (!querySnapshot.empty) {
                const categoryDoc = querySnapshot.docs[0];
                const categoryDocRef = doc(db, "categories", categoryDoc.id);

                // Update the main category document to include the updated product
                const categoryData = categoryDoc.data();
                const updatedProducts = categoryData.products
                    ? categoryData.products.map(prod => (prod.id === productId ? productSchema : prod))
                    : [productSchema];

                // If a subcategory is provided, find the subcategory and update it
                if (subCategory) {
                    const subCategoryIndex = categoryData.subCategories.findIndex(sub => sub.categoryName === subCategory);

                    if (subCategoryIndex > -1) {
                        const subCategory = categoryData.subCategories[subCategoryIndex];
                        const updatedSubCategoryProducts = subCategory.products
                            ? subCategory.products.map(prod => (prod.id === productId ? productSchema : prod))
                            : [productSchema];
                        categoryData.subCategories[subCategoryIndex].products = updatedSubCategoryProducts;
                    } else {
                        console.error("No such subcategory within the main category");
                        toast.error('No such subcategory within the main category');
                    }
                }

                await updateDoc(categoryDocRef, { products: updatedProducts, subCategories: categoryData.subCategories });
                console.log("Product added to category successfully");
                toast.success('Product added to category successfully!');
            } else {
                console.error("No such category document");
                toast.error('No such category document');
            }
        } catch (error) {
            console.error("Error updating document: ", error);
            toast.error('Error updating product');
        }
    };






    return (
        <div>
            <AdminNavbar displayNameTopbar={"Edit Product" + ' (' + `${productId}` + ')'} activeItemName="Product List">
                <div className="productContainer--ap">
                    <div className="left-ap">
                        <div className="generalInformation--ap--container">
                            <p className="heading--container">General Information</p>
                            <div className="inputsforGeneral--ap">
                                <label>
                                    <p className="gi-ap--l-i">Product Name</p>
                                    <input
                                        type="text"
                                        placeholder="Enter Product Name"
                                        value={productName}
                                        onChange={(e) => setProductName(e.target.value)}
                                    />
                                </label>

                                <label>
                                    <p className="gi-ap--l-i">Product Description</p>
                                    <textarea
                                        style={{ minHeight: '200px' }}
                                        placeholder="Enter Product Description"
                                        value={productDescription}
                                        onChange={(e) => setProductDescription(e.target.value)}
                                    ></textarea>
                                </label>
                            </div>
                        </div>
                        <div className="productInventory--ap-container">
                            <p className="heading--container">Product Inventory</p>
                            <div className="inputsforGeneral--ap">
                                <div className="ap--l--gd" style={{ gridTemplateColumns: '49% 49%' }}>
                                    <label>
                                        <p className="gi-ap--l-i">SKU Number</p>
                                        <input
                                            type="text"
                                            placeholder="Enter SKU Number"
                                            value={sku}
                                            onChange={(e) => setSku(e.target.value)}
                                        />
                                    </label>
                                    <label>
                                        <p className="gi-ap--l-i">Bar Code</p>
                                        <input
                                            type="text"
                                            placeholder="Enter Bar Code"
                                            value={barcode}
                                            onChange={(e) => setBarcode(e.target.value)}
                                        />
                                    </label>
                                </div>
                                <div className="ap--l--gd" style={{ gridTemplateColumns: '32% 32% 32%' }}>
                                    <label>
                                        <p className="gi-ap--l-i">Individual Quantity</p>
                                        <input
                                            type="number"
                                            placeholder="Enter Individual Quantity"
                                            value={quantity}
                                            onChange={(e) => setQuantity(e.target.value)}
                                        />
                                    </label>
                                    <label>
                                        <p className="gi-ap--l-i">Pack Content(No of Quantity in Packs)</p>
                                        <input
                                            type="number"
                                            placeholder="Enter Pack Content"
                                            value={packQuantity}
                                            onChange={(e) => setPackQuantity(e.target.value)}
                                        />
                                    </label>
                                    <label>
                                        <p className="gi-ap--l-i">Box Content(No of Packs in Box)</p>
                                        <input
                                            type="number"
                                            placeholder="Enter Box Content"
                                            value={boxQty}
                                            onChange={(e) => setBoxQty(e.target.value)}
                                        />
                                    </label>
                                </div>
                                <div className="ap--l--gd" style={{ gridTemplateColumns: '32% 32% 32%' }}>
                                    <label>
                                        <p className="gi-ap--l-i">Min Buying Quantity</p>
                                        <input
                                            onChange={(e) => { setMinBuyin(e.target.value) }}
                                            type="number"
                                            value={minBuying}
                                            placeholder="Enter Individual Quantity"
                                        />
                                    </label>
                                    <div className="inputsforGeneral--ap" style={{ marginTop: '0px' }}>
                                        <label>
                                            <p className="gi-ap--l-i">Product Status</p>
                                            <select
                                                name="productstatus"
                                                onClick={(e) => setProductStatus(e.target.value)}
                                            >
                                                <option value="">Enabled</option>
                                                <option value="">Disabled</option>
                                                {/* Add your category options here */}
                                            </select>
                                        </label>
                                    </div>
                                    <label>
                                        <p className="gi-ap--l-i">Price(individual unit)</p>
                                        <input
                                            onChange={(e) => { setPrice(e.target.value) }}
                                            type="number"
                                            value={price}
                                            placeholder="Enter Price for Each Quantity"
                                        />
                                    </label>

                                </div>
                            </div>
                        </div>
                        <div className="metainformation--ap-container">
                            <p className="heading--container">Meta Information</p>
                            <div className="inputsforGeneral--ap">
                                <div className="ap--l--gd" style={{ gridTemplateColumns: '49% 49%' }}>
                                    <label>
                                        <p className="gi-ap--l-i">Meta Title</p>
                                        <input
                                            type="text"
                                            placeholder="Enter Meta Titles"
                                            value={metaTitle}
                                            onChange={(e) => setMetaTitle(e.target.value)}
                                        />
                                    </label>
                                    <label>
                                        <p className="gi-ap--l-i">Meta Tags</p>
                                        <input
                                            type="text"
                                            placeholder="Enter Meta Description"
                                            value={metaDescription}
                                            onChange={(e) => setMetaDescription(e.target.value)}
                                        />
                                    </label>
                                </div>
                                <label>
                                    <p className="gi-ap--l-i">Meta Description</p>
                                    <textarea
                                        style={{ minHeight: '200px' }}
                                        placeholder="Enter Meta Tags"
                                        value={metaTags}
                                        onChange={(e) => setMetaTags(e.target.value)}
                                    ></textarea>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="right-ap">
                        <div className="productMedia--ap-container">
                            <p className="heading--container">Product Media</p>
                            <div className="inputsforGeneral--ap">
                                <label>
                                    <p className="gi-ap--l-i">Featured Image</p>
                                    <label>

                                        <div className="inputFileContainer-designed">
                                            <MdOutlineCloudUpload />
                                            <p>Click or Drag to Upload</p>
                                        </div>
                                        <input
                                            type="file"
                                            className='fileVisibility'
                                            onChange={(e) => handleImageChange(e, setFeaturedImage)}
                                        />
                                    </label>
                                    {featuredImage && (
                                        <div className="image-preview">
                                            <img src={URL.createObjectURL(featuredImage)} alt="Featured" className='imgPreview--ap' />
                                        </div>
                                    )}
                                </label>

                                <label>
                                    <p className="gi-ap--l-i">Gallery Image (100px x 100px)</p>
                                    <label>
                                        <div className="inputFileContainer-designed">
                                            <MdOutlineCloudUpload />
                                            <p>Click or Drag to Upload</p>
                                        </div>
                                        <input
                                            type="file"
                                            className='fileVisibility'
                                            multiple
                                            onChange={(e) => handleImageChange(e, setGalleryImages100, true)}
                                        />
                                    </label>

                                    <div className="image-preview-container">
                                        {galleryImages100.map((image, index) => (
                                            <img key={index} src={URL.createObjectURL(image)} alt={`Gallery 100 ${index}`} className='imgPreview--ap' />
                                        ))}
                                    </div>
                                </label>
                                <label>
                                    <p className="gi-ap--l-i">Gallery Image (950px x 950px)</p>
                                    <label >
                                        <div className="inputFileContainer-designed">
                                            <MdOutlineCloudUpload />
                                            <p>Click or Drag to Upload</p>
                                        </div>
                                        <input
                                            type="file"
                                            className='fileVisibility'
                                            multiple
                                            onChange={(e) => handleImageChange(e, setGalleryImages950, true)}
                                        />
                                    </label>

                                    <div className="image-preview-container">
                                        {galleryImages950.map((image, index) => (
                                            <img key={index} src={URL.createObjectURL(image)} alt={`Gallery 950 ${index}`} className='imgPreview--ap' />
                                        ))}
                                    </div>

                                </label>
                                <div className="existingImages">
                                    {
                                        galleryImages950Url?.map((item, index) => (
                                            <div className="tooltip" key={index}>
                                                <img
                                                    src={item}
                                                    alt=""
                                                    style={{ width: '150px', height: '150px', marginRight: '10px' }}
                                                    onClick={() => handleImageClick(index)}
                                                />
                                                <span className="tooltiptext">CLICK TO REMOVE</span>
                                            </div>))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="productCategory-details-ap">
                            <p className="heading--container">Product Category</p>
                            <div className="inputsforGeneral--ap">
                                <label>
                                    <p className="gi-ap--l-i">Select Category</p>
                                    <select
                                        name="category"
                                        value={category}
                                        onChange={handleCategoryChange}
                                    >
                                        <option value="">Select Category</option>
                                        {categories.map(cat => (
                                            <option key={cat.id} value={cat.categoryName}>{cat.categoryName}</option>
                                        ))}
                                    </select>
                                </label>
                                <label>
                                    <p className="gi-ap--l-i">Select Sub Category</p>
                                    <select
                                        name="subCategory"
                                        value={subCategory}
                                        onChange={handleSubCategoryChange}
                                        disabled={!category}
                                    >
                                        <option value="">Select Sub Category</option>
                                        {subCategories.map(sub => (
                                            <option key={sub.id} value={sub.categoryName}>{sub.categoryName}</option>
                                        ))}
                                    </select>
                                </label>

                                <label>
                                    <p className="gi-ap--l-i">Product Tags</p>
                                    <div className="tags-input-container">
                                        <input
                                            type="text"
                                            placeholder="Enter Product Tag"
                                            value={tagInput}
                                            onChange={(e) => setTagInput(e.target.value)}
                                            onKeyDown={(e) => e.key === 'Enter' && handleAddTag()}
                                        />
                                        <button onClick={handleAddTag}>Add Tag</button>
                                    </div>
                                    <div className="tags-display-container">
                                        {tags.map((tag, index) => (
                                            <div key={index} className="tag-item">
                                                {tag}
                                                <button onClick={() => handleRemoveTag(index)}>x</button>
                                            </div>
                                        ))}
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="btnSubmit--ap">
                            <button className="discardChanges" onClick={() => {
                                setProductName('');
                                setProductDescription('');
                                setQuantity('');
                                setPackQuantity('');
                                setBoxQty('');
                                setSku('');
                                setBarcode('');
                                setCategory('');
                                setSubCategory('');
                                setFeaturedImage(null);
                                setGalleryImages100([]);
                                setGalleryImages950([]);
                                setTags([]);
                                setTagInput('');
                            }}>
                                Discard Changes
                            </button>
                            <button className="confirmPost" onClick={handleSubmit}>
                                Update Product
                            </button>
                        </div>
                    </div>
                </div>
            </AdminNavbar>
            <ToastContainer />

        </div>
    );
}

export default EditProduct
