import React, { useState, useEffect } from 'react'
import AdminNavbar from '../component/navbar/adminNavbar'
import TableJSXUsers from '../component/tableUsers'
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase.config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const CustomerJsx = () => {
    const [customer, setCustomer] = useState([]);

    useEffect(() => {
        const fetchCustomers = async () => {
            toast.info('Fetching Customers Info !!')
            try {
                const productsCollection = collection(db, 'userProfile');
                const productsSnapshot = await getDocs(productsCollection);
                const productsList = productsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setCustomer(productsList);
                toast.success('Customer Fetched !!')
            } catch (error) {
                console.error('Error fetching Customers:', error);
                toast.error('Customer Fetching Failed !! Reload Recommended')
            }
        };

        fetchCustomers();
    }, []);

    return (
        <div>
            <AdminNavbar displayNameTopbar="Customers" activeItemName="Customers">
                <TableJSXUsers user={customer} />
            </AdminNavbar>
            <ToastContainer />
        </div>
    )
}

export default CustomerJsx
