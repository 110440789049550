import React, { useState, useEffect, useRef } from 'react';
import AdminNavbar from '../component/navbar/adminNavbar';
import './route.css';
import { db } from '../config/firebase.config';
import { collection, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import TableCategory from '../component/tableCategory';
import TableJSXUsers from '../component/tableUsers';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TableJsxProducts from '../component/table';
import TableJsxOrders from '../component/tableOrder';

const WelcomeBoard = () => {
    const [totalOrders, setTotalOrders] = useState(0);
    const [pendingOrders, setPendingOrders] = useState(0);
    const [acceptedOrders, setAcceptedOrders] = useState(0);
    const [totalCustomers, setTotalCustomers] = useState(0);
    const [category, setCategory] = useState([]);
    const firstLoad = useRef(true); // Initialize a ref to track the first load
    const [customer, setCustomer] = useState([]);
    const [products, setProducts] = useState([]);
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const ordersCollectionRef = collection(db, 'orders'); // Adjust the collection name if needed

        // Set up a real-time listener
        const unsubscribe = onSnapshot(ordersCollectionRef, (snapshot) => {
            try {
                const orderList = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                console.log(orderList);

                setOrders(orderList);
                setLoading(false); // Set loading to false once data is fetched
            } catch (error) {
                setError('Failed to fetch orders');
                console.error('Error fetching orders:', error);
            }
        }, (error) => {
            setError('Failed to fetch orders');
            console.error('Error fetching orders:', error);
        });

        // Cleanup listener on component unmount
        return () => unsubscribe();

    }, []);
    useEffect(() => {
        const productsCollection = collection(db, 'products');

        const unsubscribe = onSnapshot(
            productsCollection,
            (snapshot) => {
                const productsList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setProducts(productsList);

                // Only show the success toast on the first load
                if (firstLoad.current) {
                    toast.success('Products Fetched !!');
                    firstLoad.current = false; // Set the ref to false after the first load
                }
            },
            (error) => {
                console.error('Error fetching products:', error);
                toast.error('Product Fetching Failed !! Reload Recommended');
            }
        );

        // Cleanup the listener on component unmount
        return () => unsubscribe();
    }, []);
    useEffect(() => {
        const fetchCustomers = async () => {
            toast.info('Fetching Customers Info !!')
            try {
                const productsCollection = collection(db, 'userProfile');
                const productsSnapshot = await getDocs(productsCollection);
                const productsList = productsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setCustomer(productsList);
                toast.success('Customer Fetched !!')
            } catch (error) {
                console.error('Error fetching Customers:', error);
                toast.error('Customer Fetching Failed !! Reload Recommended')
            }
        };

        fetchCustomers();
    }, []);
    useEffect(() => {
        const productsCollection = collection(db, 'categories');

        const unsubscribe = onSnapshot(
            productsCollection,
            (snapshot) => {
                const productsList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setCategory(productsList);

                // Only show the success toast on the first load
                if (firstLoad.current) {
                    toast.success('Products Fetched !!');
                    firstLoad.current = false; // Set the ref to false after the first load
                }
            },
            (error) => {
                console.error('Error fetching products:', error);
                toast.error('Product Fetching Failed !! Reload Recommended');
            }
        );

        // Cleanup the listener on component unmount
        return () => unsubscribe();
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch total orders
                const totalOrdersSnapshot = await getDocs(collection(db, 'orders'));
                setTotalOrders(totalOrdersSnapshot.size);

                // Fetch pending orders
                const pendingOrdersQuery = query(collection(db, 'orders'), where('status', '==', 'In Review'));
                const pendingOrdersSnapshot = await getDocs(pendingOrdersQuery);
                setPendingOrders(pendingOrdersSnapshot.size);

                // Fetch accepted orders
                const acceptedOrdersQuery = query(collection(db, 'orders'), where('status', '==', 'Accepted'));
                const acceptedOrdersSnapshot = await getDocs(acceptedOrdersQuery);
                setAcceptedOrders(acceptedOrdersSnapshot.size);

                // Fetch total customers
                const totalCustomersSnapshot = await getDocs(collection(db, 'userProfile'));
                setTotalCustomers(totalCustomersSnapshot.size);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            <AdminNavbar activeItemName={'Dashboard'} displayNameTopbar={'Dashboard'} >
                <div className="toppart-dashboard">
                    <div className="tp-d--child">
                        <p className="tp-tp-d--child">Total Orders</p>
                        <p className="bm-tp-d--child">{totalOrders}</p>
                    </div>
                    <div className="tp-d--child">
                        <p className="tp-tp-d--child">Pending Orders</p>
                        <p className="bm-tp-d--child">{pendingOrders}</p>
                    </div>
                    <div className="tp-d--child">
                        <p className="tp-tp-d--child">Accepted Orders</p>
                        <p className="bm-tp-d--child">{acceptedOrders}</p>
                    </div>
                    <div className="tp-d--child">
                        <p className="tp-tp-d--child">Total Customers</p>
                        <p className="bm-tp-d--child">{totalCustomers}</p>
                    </div>
                </div>
                <div className="gridDisplay--dashboard">
                    <div className="left--gdd">
                        <p className="headingDG">
                            Products
                        </p>
                        <TableJsxProducts products={products} />
                    </div>
                    <div className="right--gdd">
                        <p className="headingDG">
                            Customers
                        </p>
                        <TableJSXUsers user={customer} />
                    </div>
                </div>
                <div className="gridDisplay--dashboard-2">
                    <div className="left--gdd">
                        <p className="headingDG">
                            Categories
                        </p>
                        <TableCategory category={category} />
                    </div>
                    <div className="right--gdd">
                        <p className="headingDG">
                            Orders
                        </p>
                        <TableJsxOrders ordershistory={orders} />
                    </div>
                </div>
            </AdminNavbar>
            <ToastContainer />
        </div>
    );
};

export default WelcomeBoard;
