import React, { useState, useEffect } from 'react';
import AdminNavbar from '../component/navbar/adminNavbar';
import { MdOutlineCloudUpload } from 'react-icons/md';
import { collection, getDocs, query, addDoc, updateDoc, doc, getDoc, where } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import './route.css';
import { db } from '../config/firebase.config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 } from 'uuid';

const AddCategory = () => {
    const [categoryName, setCategoryName] = useState('');
    const [categoryDescription, setCategoryDescription] = useState('');
    const [galleryImage950, setGalleryImage950] = useState(null);
    const [status, setStatus] = useState('');
    const [isSubCategory, setIsSubCategory] = useState('');
    const [parentCategories, setParentCategories] = useState([]);
    const [parentCategory, setParentCategory] = useState('');
    const [uploadingImage, setUploadingImage] = useState(false);
    const [uploadingDocument, setUploadingDocument] = useState(false);

    useEffect(() => {
        const fetchCategories = async () => {
            const q = query(collection(db, 'categories'));
            const querySnapshot = await getDocs(q);
            const categories = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setParentCategories(categories);
        };
        fetchCategories();
    }, []);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setGalleryImage950(file);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (!categoryName || !status || (!galleryImage950 && isSubCategory !== 'yes')) {
            toast.error('Please fill out all required fields.');
            return;
        }

        setUploadingDocument(true);

        try {
            let galleryImageUrl = '';
            if (galleryImage950) {
                setUploadingImage(true);
                const storage = getStorage();
                const imageRef = ref(storage, `categories/${v4()}-${galleryImage950.name}`);
                const uploadTask = uploadBytesResumable(imageRef, galleryImage950);

                // Upload image to Firebase Storage
                await new Promise((resolve, reject) => {
                    uploadTask.on(
                        'state_changed',
                        null,
                        (error) => reject(error),
                        async () => {
                            galleryImageUrl = await getDownloadURL(uploadTask.snapshot.ref);
                            resolve();
                        }
                    );
                });
                setUploadingImage(false);
            }

            // If it's a subcategory, update the parent category
            if (isSubCategory === 'yes' && parentCategory) {
                const parentDocRef = doc(db, 'categories', parentCategory);
                const parentDocSnap = await getDoc(parentDocRef);

                if (parentDocSnap.exists()) {
                    const parentCategoryData = parentDocSnap.data();
                    const updatedSubCategories = [
                        ...(parentCategoryData.subCategories || []),
                        { categoryName, categoryDescription, galleryImageUrl, status }
                    ];

                    await updateDoc(parentDocRef, { subCategories: updatedSubCategories });
                    toast.success('Subcategory added to the parent category.');
                } else {
                    toast.error('Parent category not found.');
                }
            } else {
                // Create a new category document in Firestore
                await addDoc(collection(db, 'categories'), {
                    categoryName,
                    categoryDescription,
                    galleryImageUrl,
                    status,
                    isSubCategory: false
                });
                toast.success('Category added successfully.');
            }
        } catch (error) {
            toast.error('Error adding category: ' + error.message);
        } finally {
            setUploadingDocument(false);
        }
    };





    return (
        <div>
            <AdminNavbar displayNameTopbar="Add Category" activeItemName="Add Category">
                <div className="divcontainer-ac">
                    <form onSubmit={handleFormSubmit} className="formcontainer--ac">
                        <label className='labelInput--ac'>
                            <p>Category Name</p>
                            <input
                                placeholder='Enter Category Name'
                                type="text"
                                value={categoryName}
                                onChange={(e) => setCategoryName(e.target.value)}
                            />
                        </label>
                        <label>
                            <p>Category Description</p>
                            <textarea
                                placeholder='Enter Category Description'
                                value={categoryDescription}
                                onChange={(e) => setCategoryDescription(e.target.value)}
                            ></textarea>
                        </label>
                        <label>
                            <p className="gi-ap--l-i">Category Thumbnail</p>
                            <label style={{ display: 'block' }}>
                                <div className="inputFileContainer-designed">
                                    <MdOutlineCloudUpload />
                                    <p>Click or Drag to Upload</p>
                                </div>
                                <input
                                    type="file"
                                    className="fileVisibility"
                                    onChange={handleImageChange}
                                />
                            </label>
                            <div className="image-preview-container">
                                {galleryImage950 && (
                                    <img
                                        src={URL.createObjectURL(galleryImage950)}
                                        alt="Gallery 950"
                                        className="imgPreview--ap"
                                    />
                                )}
                            </div>
                        </label>
                        <label className='labelInput--ac'>
                            <p>Status</p>
                            <select
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                            >
                                <option value="">Select Status</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                            </select>
                        </label>
                        <label className='labelInput--ac'>
                            <p>Is Sub Category?</p>
                            <div className="rd--ac">
                                <label>
                                    <input
                                        type="radio"
                                        name="sub-category-check"
                                        value="yes"
                                        onChange={(e) => setIsSubCategory(e.target.value)}
                                    /> Yes
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="sub-category-check"
                                        value="no"
                                        onChange={(e) => setIsSubCategory(e.target.value)}
                                    /> No
                                </label>
                            </div>
                        </label>
                        {isSubCategory === 'yes' && (
                            <label>
                                <p>Select Parent Category</p>
                                <select
                                    value={parentCategory}
                                    onChange={(e) => setParentCategory(e.target.value)}
                                >
                                    <option value="">Select Parent Category</option>
                                    {parentCategories.map((category) => (
                                        <option key={category.id} value={category.id}>
                                            {category.categoryName}
                                        </option>
                                    ))}
                                </select>
                            </label>
                        )}
                        <div className="submitBtn--ac">
                            <button type="submit">
                                {uploadingImage ? 'UPLOADING IMAGE' : uploadingDocument ? 'UPLOADING DOCUMENT' : 'Add Category'}
                            </button>
                        </div>
                    </form>
                </div>
            </AdminNavbar>
            <ToastContainer />
        </div>
    );
};

export default AddCategory;
